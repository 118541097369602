//Phone Input Component  style
.phone_input_component {
   position: relative;

   .containerClass {
      height: 40px;
   }

   .inputClass {
      //background-color: #ff4d56;
      width: 100%;
      height: 100%;
      outline: none;
      border: '1px solid #ced4da';
      background-color: #f8f8f8;
      vertical-align: center;
      display: flex;
      align-items: center;
      color: #000;
      border-radius: 4px;
      &::placeholder {
         color: #000;
      }
   }

   .buttonClass {
      border: '1px solid #ced4da';
      border-right: 0;
      background-color: #f8f8f8;
      border-radius: 4px;
      :global .selected-flag {
         background-color: #f8f8f8 !important;
         border-top-left-radius: 4px;
         border-bottom-left-radius: 4px;
         &:active {
            background-color: #757763;
         }
      }
   }
}
