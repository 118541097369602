.projects-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
  justify-content: flex-start;
  align-items: flex-start;
}
.project {
  padding: 15px;
  border-radius: 10px;
  margin: 20px;
  width: 90%;
  height: 240px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 900px) {
  .project {
    width: calc(50% - 40px);
  }
}
@media (min-width: 1100px) {
  .project {
    width: calc(33.33% - 40px);
  }
}
.project h2 {
  font-size: 28px;
  color: rgb(60, 60, 60);
  text-transform: capitalize;
  font-family: Monospace, sans-serif;
  margin-bottom: 15px;
}
.project p {
  color: rgb(100, 100, 100);
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
}
.project > div {
  display: flex;
  justify-content: flex-end;
}
.project > div button {
  padding: 7px 25px;
  border: none;
  outline: none;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #19a7ce;
  color: #fff;
}
